import React from "react"
import SEO from "./SEO"
import Navbar from "./Navbar"
import Footer from "./Footer"

const Layout = ({ children, navClass }) => {
  return (
    <>
      <SEO />
      <main className="flex flex-col min-h-screen bg-gray-200">
        <Navbar navClass={navClass} />
        {children}
        <Footer />
      </main>
    </>
  )
}

export default Layout
