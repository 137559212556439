import React from "react";

const Navbar = ({ navClass }) => {
    return (
        <div className={`transition-all w-full fixed z-40 ${navClass}`}>
          <div className="w-full flex items-center justify-center">
            <a href="#bio" className="p-3">Bio <span role="img" aria-label="Koala Emoji">🐨</span></a>
            <a href="#shows" className="p-3">Shows <span role="img" aria-label="Location Emoji">📍</span></a>
          </div>
        </div>
    );
};

export default Navbar;
