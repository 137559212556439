import React from "react"

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className="py-12 text-center text-gray-600 text-xs bg-black text-white">
      <div className="flex gap-y-12 flex-col lg:flex-row w-full justify-between px-24 lg:text-left">
        <div className="flex flex-col">
          <h5 className="text-lg">Redes Sociales</h5>
          <ul className="pt-2">
            <li>
              <a href="https://www.facebook.com/irepelusa" target="_blank">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com/irepelusa" target="_blank">
                Twitter
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/irepelusa" target="_blank">
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCO_PoStQDgnvIwKFSjP03uw"
                target="_blank"
              >
                YouTube
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@irepelusi" target="_blank">
                TikTok
              </a>
            </li>
          </ul>
        </div>
        <div className="flex flex-col">
        </div>
        <div className="flex flex-col">
          <h5 className="text-lg">Música</h5>{" "}
          <ul className="pt-2">
            <li>
              <a
                href="https://open.spotify.com/artist/3KaNWDYObY73SDpcZBRzuw"
                target="_blank"
              >
                Spotify
              </a>
            </li>
            <li>
              <a
                href="https://www.deezer.com/us/artist/65082662"
                target="_blank"
              >
                Deezer
              </a>
            </li>
            <li>
              <a
                href="https://music.amazon.com/artists/B07RFTLMLF/irepelusa"
                target="_blank"
              >
                Amazon Music
              </a>
            </li>
            <li>
              <a
                href="https://music.apple.com/artist/irepelusa/1456103138"
                target="_blank"
              >
                Apple Music
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCO_PoStQDgnvIwKFSjP03uw"
                target="_blank"
              >
                YouTube
              </a>
            </li>
            <li>
              <a
                href="https://tidal.com/browse/artist/15712123"
                target="_blank"
              >
                Tidal
              </a>
            </li>
          </ul>
        </div>
      </div>
      <h5 className="text-lg pt-12">&copy; Irepelusa { year }</h5>
      <a href="https://www.yalungtang.com" className="block pt-12">
        coded by Yalung Tang
      </a>
    </footer>
  )
}

export default Footer
